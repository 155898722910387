import React, { useState } from "react";
import axios from "axios";
//pdf
// import Iot from "./IoT.pdf";
// import ReactSyllabus from "./ReactJS.pdf";
//form
import { Button, Col, FormGroup, Row } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Swal from "sweetalert2";

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    current_year: "",
    enrollment_number: "",
    group: "",
  });

  // const handleSelectChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     course: event.target.value,
  //   });
  // };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateintrestform = (e) => {
    e.preventDefault();
    console.log(formData);
    axios
      .post("https://api.manyversity.com/integralites/register", formData)
      .then((response) => {
        Swal.fire({
          title: "Success!",
          text: "You have registered successfuly.",
          icon: "success",
          confirmButtonText: "Ok",
        }).then(function () {
          window.location = "https://manyversity.com";
        });
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // let pdfLink = null;
  // let pdfName = "";

  // if (formData.course === "IoT with Python") {
  //   pdfLink = Iot;
  //   pdfName = "IoT with Python Syllabus";
  // } else if (formData.course === "React JS") {
  //   pdfLink = ReactSyllabus;
  //   pdfName = "ReactJS Syllabus";
  // }
  return (
    <>
      <div className="container">
        <div className="registration-form">
          <div className="top_heading_here">
            <h3 className="mt-5 " style={{ color: '#004644' }}>Department of Computer Science and Engineering <br /> Integeral University </h3>
            <h4 className="mb-2 text-dark">
              In-house Summer Training on MERN in mix mode
              {/* Value Added Course on Web Development */}
            </h4>
            <p className="mb-0 fs-5" style={{fontWeight:'400'}}><span>Training Date:</span> 1st June - 14th July 2024</p>
            <p className="mb-5 " style={{color:'#ff3c00',fontWeight:'500',fontSize:'15px'}}>* Dates may vary as per the final exam schedule.</p>
          </div>

          <div className="form_box border bg-light px-5 mt-2">
            <Row>
              <Col md={12} className="RegisterText pt-4">
                <h3>Register Here!</h3>
              </Col>
            </Row>
            <AvForm
              style={{ marginTop: "25px" }}
              onValidSubmit={validateintrestform}
            >

              <Row>
                <Col lg={6}>
                  <AvField
                    name="name"
                    id="name"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleInputChange}
                    type="text"
                    validate={{ required: { value: true } }}
                  />
                </Col>
                <Col lg={6}>
                  <AvField
                    name="enrollment_number"
                    id="enrollment_number"
                    placeholder="Enter Enrollment Number"
                    value={formData.enrollment_number}
                    onChange={handleInputChange}
                    type="text"
                    validate={{ required: { value: true } }}
                  />
                </Col>
                <Col lg={6}>
                  <AvField
                    name="mobile"
                    //="Email"
                    type="number"
                    id="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    placeholder="Enter Mobile(WhatsApp No.)"
                    validate={{ required: { value: true } }}
                  />
                </Col>
                <Col lg={6}>
                  <AvField
                    name="email"
                    //label="Email"
                    type="email"
                    id="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Enter Email(IUL)"
                    validate={{ required: { value: true } }}
                  />
                </Col>
                <Col lg={6}>
                  <AvField
                    name="current_year"
                    // label="Email"
                    type="text"
                    id="current_year"
                    value={formData.year}
                    onChange={handleInputChange}
                    placeholder="Enter Year"
                    validate={{ required: { value: true } }}
                  />
                </Col>
                <Col lg={6}>
                  <AvField
                    name="group"
                    //label="Email"
                    type="text"
                    id="group"
                    value={formData.state}
                    onChange={handleInputChange}
                    placeholder="Enter Group"
                    validate={{ required: { value: true } }}
                  />
                </Col>


                <FormGroup className="mb-0 text-center">
                  <div>
                    <Button type="submit" color="primary" className="mr-1 mt-3 mb-2">
                      Submit
                    </Button>
                  </div>
                </FormGroup>
              </Row>
            </AvForm>
          </div>

        </div>
      </div>



      {/* <Col lg={6}>
                <AvField
                  name="current_year"
                  // label="Email"
                  type="select"
                  id="year"
                  value={formData.current_year}
                  onChange={handleInputChange}
                  placeholder="Enter Year"
                  validate={{ required: { value: false } }}
                >
                  <option value="">--Select Year--</option>
                  <option value="Third Year">Third Year</option>
                  <option value="Fourth Year">Fourth Year</option>
                </AvField>
              </Col> */}

      {/* <Col lg={6}>
                <AvField
                  name="course"
                  //label="Email"
                  id="course"
                  type="select"
                  value={formData.course}
                  onChange={handleSelectChange}
                  placeholder="Select Course"
                  validate={{ required: { value: false } }}
                >
                  <option>--Select Course--</option>
                  <option value="IoT with Python">IoT with Python</option>
                  <option value="React JS">React JS</option>
                </AvField>
              </Col> */}

      {/* <Col lang={6}>
                <FormGroup>
                  {pdfLink && (
                    <a
                      href={pdfLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      download
                    >
                      {pdfName}{" "}
                    </a>
                  )}
                </FormGroup>
              </Col> */}

      {/* <hr />
              <p className="text-danger p-2 d-flex justify-content-center">
                {" "}
                **Tentative fee for this course is 5000 INR and can vary,
                depending on the number of students registration
              </p>
              <hr /> */}

    </>
  );
};

export default RegisterForm;

