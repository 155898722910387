import React from 'react'
import logo from "./logo.png";
import { Row, Col, Container, Button } from "react-bootstrap";
import RegisterForm from "./RegisterForm";
const Home = () => {
    return (
        <>
            <Container>
                <Row style={{ backgroundColor: "#004644", textAlign: "left" }}>
                    <Col>
                        <img src={logo} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <RegisterForm />
                        {/* <a href="/registeredUsers">registeredUsers</a> */}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Home
