import React, { useEffect, useState } from 'react'
import logo from "./logo.png";
import { Row, Col, Container, Table } from "react-bootstrap";
import axios from "axios";

const RegisteredUser = () => {
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        getData();
    }, [])
    const getData = async () => {
        try {
            const response = await axios.get("https://api-obb.onebigbit.com/visitors/contact_integral");
            setUserData(response.data.data);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <>
            <Container>
                <Row style={{ backgroundColor: "#004644", textAlign: "left" }}>
                    <Col>
                        <img src={logo} />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='mt-5'>
                        <div className="registered_user bg-light">
                            <Row>
                                <Col md={12} className="RegisterText pb-4">
                                    <h3>All Registered User!</h3>
                                </Col>
                            </Row>
                            <div className="card userTableHere">
                                <Table className='table table-bordered table-striped '>
                                    <thead>
                                        <tr>
                                            <th scope="col" >Sr. No.</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Mobile</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Current Year</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {userData && userData.length > 0 ? (
                                            userData.map((data, idx) =>
                                                <tr>
                                                    <td>{idx + 1}</td>
                                                    <td>{data.name}</td>
                                                    <td>{data.mobile}</td>
                                                    <td>{data.email}</td>
                                                    <td>{data.current_year}</td>
                                                </tr>
                                            )
                                        ) : (
                                            ''
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default RegisteredUser
