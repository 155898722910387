import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./Home"
import RegisteredUser from "./RegisteredUser"

const AllRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/registereduser' element={<RegisteredUser />} />
            </Routes>
        </Router>
    );
};

export default AllRoutes
