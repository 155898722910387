
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AllRoutes from "./AllRoutes";


function App() {
  return (
    <AllRoutes />
  );
}

export default App;
